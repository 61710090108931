import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { Button, Table } from "semantic-ui-react";

export const UploadListItem = ({
  upload,
  handleDeleteViewer,
  handleEditViewer,
}) => {
  if (upload) {
    return (
      <Table.Row key={upload.id}>
        <Table.Cell>
          <Link target="_blank" to={`/viewer?id=${upload.id}`}>
            {upload.name}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {upload.id}
        </Table.Cell>
        <Table.Cell>
          {DateTime.fromISO(upload.createdAt).toLocaleString(
            DateTime.DATETIME_SHORT,
          )}
        </Table.Cell>
        <Table.Cell collapsing>
          <Link target="_blank" to={`/viewer?id=${upload.id}`}>
            <Button basic color="black">
              View Bundle
            </Button>
          </Link>
          <Button
            basic
            color="black"
            // icon
            onClick={() => {
              copyViewerUrl(upload.id);
            }}
          >
            Copy Link
          </Button>
          <Button
            basic
            color="black"
            // icon
            onClick={() => {
              handleEditViewer(upload.id, upload.name);
            }}
          >
            Edit
          </Button>
          <Button
            basic
            color="black"
            // icon
            onClick={() => {
              handleDeleteViewer(upload.id, upload.name);
            }}
          >
            Delete
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }
};

const copyViewerUrl = (viewerId) => {
  const origin = window.location.origin;

  navigator.clipboard.writeText(`${origin}/viewer?id=${viewerId}`);
};
