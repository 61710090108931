import { Form } from "semantic-ui-react";
import ReactQuill from "react-quill";
import { useEffect, useState } from "react";
import { ActionModal } from "./ActionModal";
import "react-quill/dist/quill.snow.css";

export const EditCommentModal = ({
  actionHandler,
  isOpen,
  openHandler,
  closeHandler,
  currentValue,
}) => {
  const [editCommentValue, setEditCommentValue] = useState("");

  useEffect(() => {
    if (currentValue) {
      setEditCommentValue(currentValue);
    }
  }, [currentValue]);

  return (
    <ActionModal
      actionHandler={() => actionHandler(editCommentValue)}
      isOpen={isOpen}
      openHandler={openHandler}
      closeHandler={() => {
        closeHandler();
        setEditCommentValue("");
      }}
      title="Edit Comment"
      content={
        <Form reply className="replyForm">
          <ReactQuill
            theme="snow"
            value={editCommentValue}
            onChange={(v) => setEditCommentValue(v)}
            className="commentField"
          />
        </Form>
      }
      cancelText="Cancel"
      actionText="Save"
    />
  );
};
