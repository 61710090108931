import { Header, Table } from "semantic-ui-react";
import { UploadListItem } from "./UploadListItem";

export const UploadsList = ({
  uploads,
  handleDeleteViewer,
  handleEditViewer,
}) => {
  return (
    <div className="viewersList">
      <Header as="h2">Uploads List</Header>
      {uploads?.length > 0 ? (
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Bundle Name</Table.HeaderCell>
              <Table.HeaderCell>Bundle Id</Table.HeaderCell>
              <Table.HeaderCell>Upload date</Table.HeaderCell>
              {/* <Table.HeaderCell>Viewer</Table.HeaderCell> */}
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {uploads?.map((upload) => (
              <UploadListItem
                key={upload.id}
                upload={upload}
                handleDeleteViewer={handleDeleteViewer}
                handleEditViewer={handleEditViewer}
              />
            ))}
          </Table.Body>
        </Table>
      ) : (
        <div>No uploads yet</div>
      )}
    </div>
  );
};
