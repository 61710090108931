import { Button, Header, Icon, Modal } from "semantic-ui-react";

export const ActionModal = ({
  isOpen,
  openHandler,
  closeHandler,
  title,
  content,
  actionHandler,
  actionText,
  cancelText,
}) => {
  return (
    <Modal
      basic
      onClose={closeHandler}
      onOpen={openHandler}
      open={isOpen}
      size="small"
    >
      <Header>{title}</Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={closeHandler}>
          <Icon name="remove" /> {cancelText || "Cancel"}
        </Button>
        <Button color="green" inverted onClick={actionHandler}>
          <Icon name="checkmark" /> {actionText || "Yes"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
