import { Comment, Divider, Header, Segment } from "semantic-ui-react";
import { BundleComment } from "./BundleComment";
import { CommentForm } from "./CommentForm";
import { useCallback, useEffect, useState } from "react";
import { DataStore, SortDirection } from "aws-amplify";
import { Discussion } from "../models";
import { ActionModal } from "./ActionModal";
import { EditCommentModal } from "./EditCommentModal";

async function onCommentsQuery(vId) {
  const comments = await DataStore.query(
    Discussion,
    (d) => d.viewerID.contains(vId),
    {
      sort: (comment) => comment.createdAt(SortDirection.ASCENDING),
    },
  );

  return comments;
}

export const CommentsBar = ({ viewerId, user }) => {
  const [comments, setComments] = useState();
  const [threadParentID, setThreadParentID] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState("");
  const [selectedForEditing, setSelectedForEditing] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [editCommentValue, setEditCommentValue] = useState("");
  const [height, setHeight] = useState(0);

  const getComments = useCallback(async () => {
    const commentsData = await onCommentsQuery(viewerId);
    setComments(commentsData);
  }, [viewerId]);

  // Submit handler for the main (parent) comments, no parentId is sent
  const handleNewCommentSubmit = async (comment) => {
    try {
      await DataStore.save(
        new Discussion({
          author: user?.attributes?.email,
          content: comment,
          viewerID: viewerId,
          parentId: threadParentID,
        }),
      );
      if (threadParentID) {
        setThreadParentID("");
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  // Delete handler for comments
  const handleDeleteComment = async (id) => {
    await DataStore.delete(Discussion, (comment) =>
      comment.or((c) => [c.id.eq(id), c.parentId.eq(id)]),
    );
    // await DataStore.delete(Discussion, id);
    setIsDeleteModalOpen(false);
  };

  // Submit handler for the comment edit.
  const handleEditSubmit = async (commentValue) => {
    const original = selectedForEditing;

    await DataStore.save(
      Discussion.copyOf(original, (updated) => {
        updated.content = commentValue;
      }),
    );

    setIsEditModalOpen(false);
    setEditCommentValue("");
  };

  const parentComments = comments?.filter((comment) => {
    return !comment.parentId;
  });

  const renderChildrenComments = (parentId) => {
    const childrenComments = comments.filter(
      (comment) => comment.parentId === parentId,
    );

    const childrenCommentsElements = childrenComments.map(
      (childComment, index) => {
        return (
          <>
            <BundleComment
              comment={childComment}
              isChild
              isOwnedByUser={user.attributes.email === childComment.author}
              deleteHandler={() => {
                setIsDeleteModalOpen(true);
                setSelectedForDeletion(childComment.id);
              }}
              editHandler={() => {
                setIsEditModalOpen(true);
                setSelectedForEditing(childComment);
              }}
            />
            {index < childrenComments.length - 1 && <Divider />}
          </>
        );
      },
    );

    return (
      <>
        {!!childrenComments.length && <Divider section />}
        <div style={{ paddingLeft: 30 }}>{childrenCommentsElements}</div>
      </>
    );
  };

  useEffect(() => {
    getComments();
    const subscription = DataStore.observe(Discussion).subscribe(() =>
      getComments(),
    );
    return () => subscription.unsubscribe();
  }, [getComments, viewerId]);

  useEffect(() => {
    const updateSize = () => {
      setHeight(window.innerHeight - 46);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div className="commentsBar" style={{ height: `${height}px` || "100%" }}>
      <Comment.Group className="commentsWrapper">
        <div className="commentsHeader">
          <Header as="h3" dividing>
            Comments
          </Header>
          <CommentForm handleNewCommentSubmit={handleNewCommentSubmit} />
        </div>
        <Divider />
        <div className="commentsList">
          {parentComments &&
            parentComments?.map((parentComment) => {
              const isThreadSelected = threadParentID === parentComment.id;
              return (
                <Segment className="commentWrapper" key={parentComment.id}>
                  <BundleComment
                    comment={parentComment}
                    setThreadParentID={setThreadParentID}
                    isActiveThread={isThreadSelected}
                    isOwnedByUser={
                      user.attributes.email === parentComment.author
                    }
                    deleteHandler={() => {
                      setIsDeleteModalOpen(true);
                      setSelectedForDeletion(parentComment.id);
                    }}
                    editHandler={() => {
                      setIsEditModalOpen(true);
                      setSelectedForEditing(parentComment);
                    }}
                  />
                  {isThreadSelected && (
                    <div style={{ marginTop: "15px" }}>
                      <CommentForm
                        handleNewCommentSubmit={handleNewCommentSubmit}
                        isReply
                        onCancelHandler={() => {
                          setThreadParentID("");
                        }}
                      />
                    </div>
                  )}
                  {renderChildrenComments(parentComment.id)}
                </Segment>
              );
            })}
        </div>
      </Comment.Group>
      {/* DELETE MODAL  */}
      <ActionModal
        isOpen={isDeleteModalOpen}
        openHandler={() => setIsDeleteModalOpen(true)}
        closeHandler={() => setIsDeleteModalOpen(false)}
        actionHandler={() => handleDeleteComment(selectedForDeletion)}
        title="Delete Comment"
        content="Are you sure you want to delete this comment?"
        cancelText="Cancel"
        actionText="Delete"
      />
      {/* EDIT MODAL */}
      <EditCommentModal
        isOpen={isEditModalOpen}
        openHandler={() => setIsEditModalOpen(true)}
        closeHandler={() => {
          setIsEditModalOpen(false);
          setSelectedForEditing("");
        }}
        currentValue={selectedForEditing.content}
        actionHandler={handleEditSubmit}
      />
    </div>
  );
};
