import { Hub } from "@aws-amplify/core";
import { Storage } from "@aws-amplify/storage";
import { DataStore } from "@aws-amplify/datastore";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useContext } from "react";
import { TitleContext } from "../context";
import { KanziView } from "../components/Kanziview";
import { Viewer } from "../models";
import {
  Container,
  Dimmer,
  Header,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";
import { CommentsBar } from "../components/CommentsBar";
import {
  getKanziPlayerReleaseVersion,
  getKzbNameFromApplicationCfg,
  getKzbVersion,
} from "../utils/kanziUtils";
import {
  cacheData,
  checkCachedData,
  estimateStorageQuotas
} from "../utils/ServiceWorkerUtils";

import withAuth from "../components/withAuth";

import { AmplifyConfigure, StorageConfigure } from "../utils/configure";

AmplifyConfigure();
StorageConfigure();

const PAGE_SIZE = 1000;

async function onViewerQuery(vId) {
  return DataStore.query(Viewer, vId);
}
function BundleViewer({ user }) {
  const [cookies, setCookie] = useCookies(["accessToken"]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [viewer, setViewer] = useState();
  const [bundleFiles, setBundleFiles] = useState();
  const [downloadedBundleFiles, setDownloadedBundleFiles] = useState(false);
  const [playerFiles, setPlayerFiles] = useState();
  const [downloadedPlayerFiles, setDownloadedPlayerFiles] = useState(false);
  const [playerReleaseVersion, setPlayerReleaseVersion] = useState("");
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [isServiceWorkerReady, setIsServiceWorkerReady] = useState(false);

  const [searchParams] = useSearchParams();
  const viewerId = searchParams.get("id");
  // eslint-disable-next-line no-unused-vars
  const { title, setTitle } = useContext(TitleContext);

  // Check if the ServiceWorker is listening for the requests
  const checkServiceWorkerRequestListener = async () => {
    // Fetch the /engine/health URL to test fetch event listener
    try {
      const response = await fetch('/engine/health');
      if (response.status === 200) {
        console.log('[BroadcastChannel:React]: SERVICE_WORKER_READY: Fetch test passed - /engine/health returned 200');
        setIsServiceWorkerReady(true);
      } else {
        console.log('[BroadcastChannel:React]: SERVICE_WORKER_NOT_LISTENING: Fetch test failed - /engine/health did not return 200');
        window.location.reload(false);
      }
    } catch (error) {
      console.error('[BroadcastChannel:React]: SERVICE_WORKER_FAILED_TO_FETCH: Fetch test failed - Error fetching /engine/health', error);
      setIsServiceWorkerReady(false);
      setIsLoading(false);
      setError('Service worker not ready!');
    }
  };

  // aws Storage.get() download progress feedback
  const storageProgressCallback = (progress) => {
    const percentProgress = ((progress.loaded/progress.total)*100).toFixed(3);
    console.log(`[React:aws:storage]: Downloaded ${percentProgress} %`);
  }

  // KanziView error handler
  const onErrorHandler = (error) => {
    setIsLoading(false);
    setError(error);
  };

  const getViewer = async (id) => {
    if (!id) return null;
    console.log(`[React]: getViewer(${id})`);
    const viewerData = await onViewerQuery(id);
    console.log("[React]: viewerData: ", viewerData);
    console.log("[React]: bundle_s3_address: ", viewerData?.bundle_s3_address);
    console.log("[React]: bundle_version: ", viewerData?.bundle_version);
    console.log("[React]: player_version: ", viewerData?.player_version);
    return viewerData;
  };

  const listBundleFilesFromStorage = async (bundle_s3_address) => {
    if (!bundle_s3_address) {
      setIsLoading(false);
      setError("Unable to resolve bundle s3 address!");
      return;
    }

    const files = await Storage.list(bundle_s3_address, {
      pageSize: PAGE_SIZE,
    }).catch((err) => {
      setIsLoading(false);
      setError("Unable to list bundle files!");
      console.log(err);
    });
    const filteredFileList = files?.results.filter(file => !file.key.endsWith('/')) || [];

    if (filteredFileList.length === 0) {
      setIsLoading(false);
      setError("Unable to find bundle files!");
      return;
    }

    console.log("[React]: Bundle files: ", filteredFileList || []);
    setBundleFiles(filteredFileList || []);
  };

  const getBundleFilesFromStorage = useCallback(async (s3_bundle_path, files) => {
    if (!files) return;
    for (let i = 0; i < files.length; i++) {
      const fileName = files[i].key.replace(`${s3_bundle_path}/`, "");
      console.log(
        `[React]: getBundleFilesFromStorage() => File info: filename=${files[i].key}, filesize=${files[i].size}`,
      );
      if (files[i].size > 0) {
        console.log(
          `[React]: getBundleFilesFromStorage() => Downloading: filename=${files[i].key}, filesize=${files[i].size}`,
        );
        console.log("[React]: extracted fileName: ", fileName);
        const fileContent = await Storage.get(`${files[i].key}`, {
          download: true,
          progressCallback: storageProgressCallback,
        });

        if (fileContent.$metadata.httpStatusCode !== 200) {
          setIsLoading(false);
          setError(`Failed to download bundle file from address: ${files[i].key}`);
          return;
        }

        const fileContentResponse = new Response(fileContent.Body);

        const uInt8Array = new Uint8Array(
          await fileContentResponse.arrayBuffer(),
        );
        window.FS.writeFile("/" + fileName, uInt8Array);
        console.log(
          `[React]: getBundleFilesFromStorage() => FS Write: file=${fileName}, size:${uInt8Array.length}`,
        );

        if (i === files.length - 1) {
          console.log("[React]: setDownloadedBundleFiles = true");
          setDownloadedBundleFiles(true);
        }
      } else {
        setIsLoading(false);
        setError(`Bundle file ${fileName} is empty!`);
        return;
      }
    }
  }, []);

  const checkIfFileExistInStorageList = (player_s3_address, fileList, fileName) => {
    const filteredFileList = fileList.filter(file => file.key.endsWith(fileName)).length > 0;
    if (!filteredFileList) {
      setIsLoading(false);
      setError(`Unable to retrieve the ${fileName} file from address: ${player_s3_address}`);
      return false;
    }
    return true;
  }

  const listPlayerFilesFromStorage = useCallback(async (player_s3_address) => {
    if (!player_s3_address) {
      setIsLoading(false);
      setError("Unable to resolve player s3 address!");
      return;
    }

    const files = await Storage.list(player_s3_address, {
      pageSize: PAGE_SIZE,
    }).catch((err) => {
      setIsLoading(false);
      setError(`Failed to list player files from address: ${player_s3_address}`);
      console.log(err);
      return;
    });

    const filteredFileList = files?.results.filter(file => !file.key.endsWith('/')) || [];
    if (filteredFileList.length === 0) {
      setIsLoading(false);
      setError(`No player files available from address: ${player_s3_address}`);
      return;
    }

    if (!checkIfFileExistInStorageList(player_s3_address, filteredFileList, 'kzb_player.js')
      || !checkIfFileExistInStorageList(player_s3_address, filteredFileList, 'kzb_player.wasm')
      || !checkIfFileExistInStorageList(player_s3_address, filteredFileList, 'kzb_player.worker.js')
    ) {
      return;
    }

    console.log(
      `[React]: Player files for ${player_s3_address}:`,
      filteredFileList || [],
    );
    setPlayerFiles(filteredFileList || []);
  },[]);

  const getPlayerFilesFromStorage = useCallback(async (files) => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type, Content-Encoding, Cache-Control, Etag, Last-Modified",
      "Access-Control-Max-Age": "86400",
      "Cross-Origin-Embedder-Policy": "require-corp",
      "Cross-Origin-Opener-Policy": "same-origin",
    };

    if (!files) return;

    for (let i = 0; i < files.length; i++) {
      const filePath = files[i].key.replace("players/", "");
      const fileName = files[i].key.split("/")[2];

      if (!fileName) continue;

      if (files[i].size > 0) {
        const path = `/engine/${filePath}`;

        // Get file metadata from s3
        const fileMetadata = await Storage.getProperties(`${files[i].key}`);
        const { contentType, eTag, lastModified } = fileMetadata;
        console.log(
          "[React]: getPlayerFilesFromStorage() => File Properties ",
          fileMetadata,
        );

        // Add s3 cache headers information
        headers["Etag"] = eTag.replaceAll('\\"', "").replaceAll("\"", "");
        headers["Last-Modified"] = lastModified;
        headers["Cache-Control"] = "max-age=864000";
        // headers["Cache-Control"] = "max-age=0, no-cache, no-store, must-revalidate"; // Disable caching
        console.log("HEADERS:", headers);
        
        // Check if ServiceWorker contains valid cached data for the specific path
        const hasCachedData = await checkCachedData(path, headers);
        console.log(
          `[React]: getPlayerFilesFromStorage() => Check if file content is already cached: ${`/engine/${filePath}`}`,
          hasCachedData
        );
        if(hasCachedData) {
          continue;
        }

        console.log(
          `[React]: getPlayerFilesFromStorage() => Downloading: filename=${files[i].key}, filesize=${files[i].size}`,
        );

        // Download file content
        const fileContent = await Storage.get(`${files[i].key}`, {
          download: true,
          progressCallback: storageProgressCallback,
        });

        if (fileContent.$metadata.httpStatusCode !== 200) {
          setIsLoading(false);
          setError(`Failed to download player file from address: ${files[i].key}`);
          return;
        }

        // Convert file content
        const fileContentResponse = new Response(fileContent.Body);
        const uInt8Array = new Uint8Array(
          await fileContentResponse.arrayBuffer(),
        );
        console.log(
          `[React]: getPlayerFilesFromStorage() => Check if file content is already cached: ${`/engine/${filePath}`}:`,
          hasCachedData
        );
        if(hasCachedData) continue;

        await cacheData(
          `/engine/${filePath}`,
          uInt8Array,
          contentType,
          headers,
        );
        console.log(
          `[React]: getPlayerFilesFromStorage() => CACHE Write: file=/engine/${filePath}, size:${uInt8Array.length}`,
        );
      } else {
        setIsLoading(false);
        setError(`Player file ${fileName} is empty!`);
        return;
      }
    }

    console.log("[React]: downloadedPlayerFiles:", true);
    setDownloadedPlayerFiles(true);
  }, []);

  const getKanziPlayerVersion = useCallback(async (viewer, files) => {
    if (!files) return;

    if (viewer?.bundle_version && viewer?.player_version) {
      const kzbVersion = viewer?.bundle_version;
      const kanziPlayerReleaseVersion = viewer?.player_version;
      console.log("[React]: Viewer:kzbVersion", kzbVersion);
      console.log("[React]: Viewer:kanziPlayerReleaseVersion", kanziPlayerReleaseVersion);
      setPlayerReleaseVersion(kanziPlayerReleaseVersion);
      return;
    }

    const kzbFileList = files.filter((file) => file.key.endsWith(".kzb"));
    const applicationConfig = files.filter((file) =>
      file.key.includes("application.cfg"),
    );
    let kzbFileToDownload = "";

    console.log("[React]: getKanziPlayerVersion:kzbFileList", kzbFileList);
    console.log("[React]: getKanziPlayerVersion:applicationConfig", applicationConfig);

    if (kzbFileList.length === 0) {
      setIsLoading(false);
      setError("Unable to list the bundle kzb file!");
      return;
    } else if (kzbFileList.length === 1) {
      // if there's only 1 kzb file in the bundle use it to find the kzb version
      console.log("[React]: Download kzb file directly", kzbFileList[0]);
      kzbFileToDownload = kzbFileList[0]?.key;
    } else if (kzbFileList.length > 1 && applicationConfig.length === 1) {
      // for a multi kzb file project, use the application.cfg to check for the main one
      console.log("[React]: Download application file", applicationConfig[0]);

      const fileRequest = await Storage.get(applicationConfig[0]?.key, {
        download: true,
        progressCallback: storageProgressCallback,
      });
      const fileData = await fileRequest?.Body?.text();
      console.log("[React]: Application.cfg", fileData);

      const kzbFileName = getKzbNameFromApplicationCfg(fileData).replace(".cfg", "");
      console.log("[React]: kzbFileName", kzbFileName);

      const kzbFileFilter = files.filter((file) =>
        file.key.includes(kzbFileName),
      );
      kzbFileToDownload = kzbFileFilter[0]?.key;
    } else {
      setIsLoading(false);
      setError("Unable to list the application.cfg file!");
      return;
    }
    console.log("[React]: kzbFileToDownload", kzbFileToDownload);

    // download the kzb file
    const kzbFileContentRequest = await Storage.get(kzbFileToDownload, {
      download: true,
      progressCallback: storageProgressCallback,
    });
    const fileContentResponse = new Response(kzbFileContentRequest?.Body);
    const fileContentUInt8Array = new Uint8Array(
      await fileContentResponse.arrayBuffer(),
    );

    const kzbVersion = getKzbVersion(fileContentUInt8Array);
    console.log("[React]: kzbVersion", kzbVersion);

    if (!kzbVersion) {
      setIsLoading(false);
      setError("Unable to get the kzb version!");
      return;
    }

    const kanziPlayerReleaseVersion = await getKanziPlayerReleaseVersion(kzbVersion);
    console.log(
      "[React]: kanziPlayerReleaseVersion",
      kanziPlayerReleaseVersion,
    );

    if (!kanziPlayerReleaseVersion) {
      setIsLoading(false);
      setError("Unable to resolve the kzb player version!");
      return;
    }

    setPlayerReleaseVersion(kanziPlayerReleaseVersion);
  }, []);

  const onKanziViewPlayerReadyEvent = (e) => {
    console.log("[React:event]: onKanziViewPlayerReadyEvent: ", e);
    setIsPlayerReady(true);
  };

  const onKanziViewPlayerStartEvent = (e) => {
    console.log("[React:event]: onKanziViewPlayerStartEvent: ", e);
    setIsLoading(false);
  };

  const onKanziErrorEvent = (e) => {
    const errorMessage = e?.detail?.error || e?.message;
    console.log("[React:event]: onKanziErrorEvent: ", errorMessage);
    setIsLoading(false);
    setError(errorMessage);
  };

  const onErrorEvent = (e) => {
    console.log("[React:event]: onErrorEvent: ", e?.error);
    setIsLoading(false);
    setError(e?.error);
  };

  const onUnhandledrejectionEvent = (e) => {
    console.log("[React:event]: onUnhandledrejectionEvent: ", e?.reason);
    setIsLoading(false);
    setError(e?.reason);
  };

  const updateTitleBar = useCallback(() => {
    setTitle(
      `Bundle name: ${viewer?.name}, Viewer version: ${playerReleaseVersion}`,
    );
  }, [setTitle, viewer?.name, playerReleaseVersion]);

  // Check ServiceWorker state
  useEffect(() => {
    const activeWorkerState = window?.ServiceWorker?.activeWorkerState || "unknown";
    console.log('[React:ServiceWorkerState]:', activeWorkerState);
    if (!isServiceWorkerReady && (activeWorkerState === 'ready' || activeWorkerState === 'activated')) {
      checkServiceWorkerRequestListener();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.ServiceWorker?.activeWorkerState]);

  // Register to browser events: kzb_utils.js, kzb_player.js
  useEffect(() => {
    window.addEventListener(
      "KanziViewPlayerReady",
      onKanziViewPlayerReadyEvent,
    );
    window.addEventListener(
      "KanziViewPlayerStart",
      onKanziViewPlayerStartEvent,
    );
    window.addEventListener("KanziError", onKanziErrorEvent);
    window.addEventListener('error', onErrorEvent);
    window.addEventListener('unhandledrejection', onUnhandledrejectionEvent);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener(
        "KanziViewPlayerReady",
        onKanziViewPlayerReadyEvent,
      );
      window.removeEventListener(
        "KanziViewPlayerStart",
        onKanziViewPlayerStartEvent,
      );
      window.removeEventListener("KanziError", onKanziErrorEvent);
      window.removeEventListener('error', onErrorEvent);
      window.removeEventListener('unhandledrejection', onUnhandledrejectionEvent);
    };
  }, []);

  useEffect(() => {
    const accessToken = user?.signInUserSession?.accessToken.jwtToken;
    if(accessToken && accessToken !== cookies?.accessToken) {
      console.log('[React:useEffect:auth] set user cookies');
      setCookie("accessToken", accessToken, { path: "/" });
    }
  }, [user, user?.signInUserSession?.accessToken.jwtToken, cookies, setCookie]);

  useEffect(() => {
    console.log("[React:useEffect] Register to datastore events");
    // Create listener that will stop observing the model once the sync process is done
    const removeListener = Hub.listen("datastore", async (capsule) => {
      const {
        payload: { event },
      } = capsule;

      if (event === "ready") {
        const viewerData = await getViewer(viewerId);
        setViewer(viewerData);
        if (!viewerData) {
          setIsLoading(false);
          setError("Viewer data not found!");
        }
      }
    });

    // Start the DataStore, this kicks-off the sync process.
    DataStore.start();

    return () => {
      removeListener();
    };
  }, [viewerId]);

  useEffect(() => {
    if (viewer?.bundle_s3_address) {
      console.log("[React:useEffect] listBundleFilesFromStorage()");
      listBundleFilesFromStorage(viewer?.bundle_s3_address); // => setBundleFiles()
    }
  }, [viewer, viewer?.bundle_s3_address]);

  useEffect(() => {
    if (bundleFiles) {
      console.log("[React:useEffect] getKanziPlayerVersion()", bundleFiles);
      getKanziPlayerVersion(viewer, bundleFiles); // => setPlayerReleaseVersion()
    }
  }, [viewer, bundleFiles, getKanziPlayerVersion]);

  useEffect(() => {
    if (playerReleaseVersion) {
      console.log("[React:useEffect] listPlayerFilesFromStorage()");
      listPlayerFilesFromStorage(`players/${playerReleaseVersion}/`); // => setPlayerFiles()
      updateTitleBar();
    }
  }, [playerReleaseVersion, listPlayerFilesFromStorage, updateTitleBar]);

  useEffect(() => {
    if (isServiceWorkerReady && playerReleaseVersion && playerFiles) {
      console.log(
        "[React:useEffect] getPlayerFilesFromStorage()",
        isServiceWorkerReady,
        playerReleaseVersion,
        playerFiles,
      );
      estimateStorageQuotas();
      getPlayerFilesFromStorage(playerFiles); // => setDownloadedPlayerFiles()
    }
  }, [isServiceWorkerReady, playerReleaseVersion, playerFiles, getPlayerFilesFromStorage]);

  useEffect(() => {
    if (
      isPlayerReady &&
      isServiceWorkerReady &&
      playerReleaseVersion &&
      bundleFiles &&
      downloadedPlayerFiles
    ) {
      console.log("[React:useEffect] getBundleFilesFromStorage()");
      getBundleFilesFromStorage(viewer?.bundle_s3_address, bundleFiles); // => setDownloadedBundleFiles()
    }
  }, [
    isPlayerReady,
    isServiceWorkerReady,
    playerReleaseVersion,
    bundleFiles,
    downloadedPlayerFiles,
    viewer?.bundle_s3_address,
    getBundleFilesFromStorage
  ]);

  useEffect(() => {
    if (downloadedBundleFiles && downloadedPlayerFiles) {
      console.log("[React:useEffect] window.Module.loadKanziViewFiles()");
      window.Module.loadKanziViewFiles();
    }
  }, [downloadedBundleFiles, downloadedPlayerFiles]);

  return (
    <div className="Viewer">
      {isLoading && (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}
      <div className="viewerWrapper" id="viewer-container">
        {error ? (
          <Container>
            <Segment
              style={{
                // display: "flex",
                // justifyContent: "center",
                marginTop: 50,
              }}
            >
              <Header as="h2">Something went wrong...</Header>
              <Message
                icon="times circle outline"
                header="Failed to load the bundle"
                content={error}
              />
            </Segment>
          </Container>
        ) : (
          <>
          {playerReleaseVersion && downloadedPlayerFiles && (
            <KanziView playerVersion={playerReleaseVersion} onErrorHandler={onErrorHandler} />
          )}
          </>
        )}
      </div>
      <CommentsBar viewerId={viewerId} user={user} />
    </div>
  );
}

export default withAuth(BundleViewer);
