import { Amplify } from "@aws-amplify/core";
import { Storage } from "@aws-amplify/storage";
import {
  aws_cognito_domain,
  aws_cognito_app_client_redirect_url_sign_in,
  aws_cognito_app_client_redirect_url_sign_out
} from "../aws-custom-config";

import awsExports from "../aws-exports";

// remove empty default oauth property to avoid issues when using a custom OpenIDConnect provider
delete awsExports['oauth'];

export const AmplifyConfigure = () => {
  Amplify.configure({
    ...awsExports,
    Auth: {
      region: awsExports.aws_cognito_region,
      userPoolId: awsExports.aws_user_pools_id,
      userPoolWebClientId: awsExports.aws_user_pools_web_client_id,
      oauth: {
        domain: aws_cognito_domain,
        scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
        redirectSignIn: aws_cognito_app_client_redirect_url_sign_in,
        redirectSignOut: aws_cognito_app_client_redirect_url_sign_out,
        responseType: "token"
      },
      federationTarget: "COGNITO_USER_POOLS",
    }
  });
}

export const StorageConfigure = () => {
  //configure Storage with config for unauthenticated storage access
  Storage.configure({ level: "public" });
}