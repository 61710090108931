import { Button } from "semantic-ui-react";
import { aws_cognito_debug_oauth_flow } from "../aws-custom-config";

const AuthSignIn = ({ state, signIn = ()=>{} }) => {
  return (state === 'unauthenticated' && aws_cognito_debug_oauth_flow ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Button basic color="black" onClick={signIn}>Sign in with Kanzi Portal</Button>
      </div>
    ) : null
  )
};

export default AuthSignIn;