import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import { TitleContext } from "../context";
import withAuth from "../components/withAuth";

import "@aws-amplify/ui-react/styles.css";

const Root = ({ user, signIn, signOut }) => {
  const [title, setTitle] = useState("Uploads list");

  return (
    <>
      <TitleContext.Provider value={{ title, setTitle }}>
        <div className="App">
          <div className="navWrapper" id="nav">
            <Menu className="nav">
              <Menu.Item>
                <a href={`/upload`}>
                  <Icon name="home" />
                </a>
              </Menu.Item>
              <Menu.Item onClick={signOut} >
                <Icon name="sign out" />
              </Menu.Item>
              <Menu.Item fitted="vertically" className="titleWrapper">
                Kanzi Web Player - {title}
              </Menu.Item>
            </Menu>
          </div>
          <div>
          {user ? (
            <>
              <div className="contentWrapper">
                <Outlet test="123" />
              </div>
            </>
          ) : null
          }
        </div>
      </div>
      </TitleContext.Provider>
    </>
  );
};

export default withAuth(Root);
