import { Button } from "semantic-ui-react";
import { aws_cognito_debug_oauth_flow } from "../aws-custom-config";
import withAuth from "./withAuth";

const AuthSignOut = ({ user, signOut = ()=>{ } }) => {

  return (
    <>
      {aws_cognito_debug_oauth_flow && user ? (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <span><b>Username:</b> {user?.getUsername()}</span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <span><b>Email:</b> {user?.attributes?.email}</span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Button basic color="black" onClick={signOut}>Sign out</Button>
          </div>
        </>
      ) : null
    }
    {!aws_cognito_debug_oauth_flow && user ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <span>You are currently signed in.</span>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <span>You have been successfully signed out.</span>
        </div>
      )
    }
    </>
  )
};

export default withAuth(AuthSignOut);