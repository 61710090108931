import { v4 } from "uuid";
import { Storage } from "@aws-amplify/storage";
import { DataStore, SortDirection } from "@aws-amplify/datastore";
import { useCallback, useEffect, useRef, useState } from "react";

import {
  Button,
  Container,
  Dimmer,
  Divider,
  Form,
  Header,
  Input,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";
import { Viewer } from "../models";
import { UploadsList } from "../components/UploadsList";
import { BundleInfo } from "../components/BundleInfo";
import { ActionModal } from "../components/ActionModal";
import {
  generateApplicationCfg,
  generateManifestFile,
  getBundleInfo,
} from "../utils/kanziUtils";
import withAuth from "../components/withAuth";

import { AmplifyConfigure, StorageConfigure } from "../utils/configure";

AmplifyConfigure();
StorageConfigure();

const PAGE_SIZE = 1000;

function Upload({ user }) {
  const fileInput = useRef();
  const [bundleInfo, setBundleInfo] = useState({
    name: "",
    fileNames: [],
    nameSource: "",
  });
  const [deleteError, setDeleteError] = useState("");
  const [bundleError, setBundleError] = useState("");
  const [uploads, setUploads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState({
    id: "",
    name: "",
  });
  const [selectedForEdit, setSelectedForEdit] = useState({ id: "", name: "" });
  const [customNameValue, setCustomNameValue] = useState("");

  const fetchViewers = useCallback(async () => {
    const author = user?.attributes?.email;
    if (author) {
      const viewers = await DataStore.query(
        Viewer,
        (v) => v.author.contains(author),
        {
          sort: (viewer) => viewer.createdAt(SortDirection.DESCENDING),
        },
      );

      setUploads(viewers);
    }
  }, [user]);

  useEffect(() => {
    fetchViewers();
    const subscription = DataStore.observe(Viewer).subscribe(() =>
      fetchViewers(),
    );
    return () => subscription.unsubscribe();
  }, [fetchViewers]);

  const handleChange = async () => {
    setBundleError("");
    const inputValue = fileInput?.current?.files;

    const selectedFiles = Object.values(inputValue);

    const bundleInfo = await getBundleInfo(selectedFiles, setBundleError);

    setBundleInfo(bundleInfo);
  };

  const handleUploadClick = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    let newArr = fileInput?.current?.files;
    const folderName = `bundles/${v4()}`;

    // auto generate application.cfg file in case it is missing from the user selected files
    const autogeneratedApplicationCfg = await generateApplicationCfg(newArr);
    if (autogeneratedApplicationCfg) {
      newArr = [...newArr, autogeneratedApplicationCfg];
    }

    // auto generate manifest.txt file in case it is missing from the user selected files
    const autogeneratedManifest = await generateManifestFile(newArr);
    if (autogeneratedManifest) {
      newArr = [...newArr, autogeneratedManifest];
    }

    // Upload the user selected files
    for (let i = 0; i < newArr.length; i++) {
      await handleUpload(folderName, newArr[i]);
    }

    await DataStore.save(
      new Viewer({
        bundle_s3_address: `${folderName}`,
        bundle_version: bundleInfo?.bundleVersion,
        player_version: bundleInfo?.playerVersion,
        name: customNameValue || bundleInfo.name,
        author: user?.attributes?.email,
      }),
    );

    fileInput.current.value = null;
    setIsLoading(false);
    setCustomNameValue("");
    setBundleInfo({ name: "", fileNames: [], nameSource: "" });
  };

  const handleDeleteViewer = async (bundle) => {
    setIsLoading(true);

    const { bundle_s3_address } = await DataStore.query(Viewer, bundle.id);
    const files = await Storage.list(bundle_s3_address, {
      pageSize: PAGE_SIZE,
    }).catch((err) => {
      setIsLoading(false);
      setIsDeleteModalOpen(false);
      setDeleteError("Unable to list bundle files!");
      return;
    });
    if ((files?.results || []).length === 0) {
      setIsLoading(false);
      setIsDeleteModalOpen(false);
      setDeleteError("Unable to find bundle files!");
      return;
    }

    // Delete bundle s3 files
    for (let i = 0; i < files.results.length; i++) {
      const res = await Storage.remove(`${files.results[i].key}`);
      const { httpStatusCode } = res.$metadata;
      if (httpStatusCode !== 204) {
        setIsLoading(false);
        setIsDeleteModalOpen(false);
        setDeleteError(`Unable to delete bundle file: ${files.results[i].key}`);
        return;
      }
    }

    // Delete bundle Viewer data
    await DataStore.delete(Viewer, bundle.id);
    setIsLoading(false);
    setIsDeleteModalOpen(false);
  };

  const handleEditViewer = async (bundle) => {
    setIsLoading(true);

    const original = await DataStore.query(Viewer, bundle.id);

    await DataStore.save(
      Viewer.copyOf(original, (updated) => {
        updated.name = customNameValue;
      }),
    );

    setIsEditModalOpen(false);
    setCustomNameValue("");
    setIsLoading(false);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setCustomNameValue("");
  };

  const handleUpload = async (folder, file) => {
    const filePath = `${folder}/${file.name}`;
    console.log(`uploading => ${filePath}`);
    let savedFile = await Storage.put(filePath, file, {
      cacheControl: "max-age=864000", // browser cache valid for 10 days
      progressCallback: (x) => {
        console.log(x);
      },
    }).catch((err) => {
      console.log(err);
    });
    console.log("saved file =>", savedFile);
  };

  return (
    <Container>
      <div className="uploadWrapper">
        <Header as="h2">Upload Kanzi bundle files</Header>
        <form className="upload-steps" onSubmit={handleUploadClick}>
          <Segment>
            <label>
              <span className="labelText">Select Files:</span>

              <input
                type="file"
                multiple
                ref={fileInput}
                onChange={handleChange}
              />
            </label>
          </Segment>
          {bundleInfo?.fileNames.length > 0 && !bundleError && (
            <>
              <Divider />
              <BundleInfo
                bundleInfo={bundleInfo}
                customNameValue={customNameValue}
                setCustomNameValue={setCustomNameValue}
              />
              <Button
                primary
                type="submit"
                content="Upload"
                disabled={!!bundleError}
              />
            </>
          )}
          {bundleError && (
            <div>
              <Message compact warning>
                {bundleError}
              </Message>
            </div>
          )}
        </form>
        <Divider />

        {deleteError && (
          <Container>
            <Segment
              style={{
                marginTop: 15,
              }}
            >
              <Header as="h2">Something went wrong...</Header>
              <Message
                icon="times circle outline"
                header="Failed to delete the bundle"
                content={deleteError}
              />
            </Segment>
          </Container>
        )}

        {isLoading ? (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        ) : (
          <Segment>
            <UploadsList
              uploads={uploads}
              handleDeleteViewer={(id, name) => {
                setIsDeleteModalOpen(true);
                setSelectedForDeletion({ id, name });
              }}
              handleEditViewer={(id, name) => {
                setSelectedForEdit({ id, name });
                setIsEditModalOpen(true);
                setCustomNameValue(name);
              }}
            />
          </Segment>
        )}
      </div>
      <ActionModal
        isOpen={isDeleteModalOpen}
        closeHandler={() => setIsDeleteModalOpen(false)}
        actionHandler={() => handleDeleteViewer(selectedForDeletion)}
        title="Delete Bundle"
        content={`Are you sure you want to delete bundle "${selectedForDeletion.name}"?`}
        cancelText="Cancel"
        actionText="Delete"
      />
      <ActionModal
        isOpen={isEditModalOpen}
        closeHandler={handleCloseEditModal}
        actionHandler={() => handleEditViewer(selectedForEdit)}
        title="Edit Bundle"
        content={
          <Segment>
            <Form reply className="replyForm">
              <Input
                label="Bundle Name"
                type="text"
                value={customNameValue}
                onChange={(e) => setCustomNameValue(e.target.value)}
              />
            </Form>
          </Segment>
        }
        cancelText="Cancel"
        actionText="Save"
      />
    </Container>
  );
}

export default withAuth(Upload);
