import { Button, Form } from "semantic-ui-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useState } from "react";

export const CommentForm = ({
  handleNewCommentSubmit,
  isReply,
  onCancelHandler,
}) => {
  const [newCommentValue, setNewCommentValue] = useState("");

  const submitHandler = async () => {
    const response = await handleNewCommentSubmit(newCommentValue);
    if (response) {
      setNewCommentValue("");
    }
  };

  return (
    <Form reply className="replyForm" onSubmit={submitHandler}>
      <ReactQuill
        theme="snow"
        value={newCommentValue}
        onChange={(e) => setNewCommentValue(e)}
        className="commentField"
      />
      <Button
        content="Cancel"
        secondary
        type="button"
        onClick={() => {
          setNewCommentValue("");
          onCancelHandler && onCancelHandler();
        }}
      />
      <Button content={isReply ? "Reply" : "Comment"} primary type="submit" />
    </Form>
  );
};
