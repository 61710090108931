// internal helper functions
const _postMessage = (type) => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      const messageChannel = new MessageChannel();
      messageChannel.port1.onmessage = (event) => {
        console.log('[ServiceWorker:React]: Response from service worker:', event.data);
      };

      registration.active.postMessage({ type }, [messageChannel.port2]);
    });
  }
}

const _postAsyncMessage = async (type, data) => {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.ready;
    const channel = new MessageChannel();

    return new Promise((resolve) => {
      channel.port1.onmessage = (event) => {
        if (event.data && event.data.status === 'SUCCESS') {
          if (type === 'CACHE_DATA') {
            console.log('[ServiceWorker:React]: File cached successfully');
            resolve(true);
          } else if (type === 'GET_CACHE_DATA') {
            console.log(`[ServiceWorker:React]: Get cached data for ${data.path}`,);
            resolve(event?.data?.request);
          } else if (type === 'CHECK_CACHE_DATA') {
            console.log('[ServiceWorker:React]: File has cached data ?', event?.data?.hasCachedData);
            resolve(event?.data?.hasCachedData);
          }
        }
      };

      registration.active.postMessage(
        { type, data },
        [channel.port2]
      );
    });
  }
}

// ServiceWorker: cache data
export const cacheData = async (path, content, contentType, headers) => {
  return _postAsyncMessage('CACHE_DATA', { path, content, contentType, headers });
};

// ServiceWorker: get the cached data for the path
export const getCachedData = async (path) => {
  return _postAsyncMessage('GET_CACHE_DATA', { path });
};

// ServiceWorker: check if cache already exists for the path
export const checkCachedData = async (path, headers) => {
  return _postAsyncMessage('CHECK_CACHE_DATA', { path, headers });
};

// ServiceWorker: clear all cache
export const clearCache = async () => {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.ready;
    const channel = new MessageChannel();

    channel.port1.onmessage = (event) => {
      if (event.data && event.data.status === 'SUCCESS') {
        console.log('[ServiceWorker:React]: Cache cleared successfully');
      }
    };

    registration.active.postMessage(
      { type: 'CLEAR_CACHE' },
      [channel.port2]
    );
  }
};

// ServiceWorker: estimate storage quotas
export const estimateStorageQuotas = async () => {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    try {
      const { usage, quota } = await navigator.storage.estimate();
      const percentUsed = ((usage / quota) * 100).toFixed(3);
      const usageInMib = ((usage / (1024 * 1024)) * 1.04858).toFixed(3);
      const quotaInMib = ((quota / (1024 * 1024)) * 1.04858).toFixed(3);

      console.log(
        "[ServiceWorker:React]: estimate storage quotas: " +
          `${usageInMib} MB out of ${quotaInMib} MB used (${percentUsed} %)`
      );
    } catch (error) {
      console.error('Error estimating storage quotas:', error);
    }
  } else {
    // Handle the case where navigator.storage.estimate() is not supported.
    console.warn('Storage estimate not supported in this browser.');
  }
};

// ServiceWorker: list cached files
export const listCachedFiles = () => {
  _postMessage('LIST_CACHED_FILES');
}

// ServiceWorker: show request statistics
export const showRequestStatistics = () => {
  _postMessage('SHOW_REQUEST_STATISTICS');
}

// ServiceWorker: reset request statistics
export const resetRequestStatistics = () => {
  _postMessage('RESET_REQUEST_STATISTICS');
}

// expose functions for debug
window.ServiceWorker.clearCache = function() {
  clearCache();
};

window.ServiceWorker.listCachedFiles = function() {
  listCachedFiles();
};

window.ServiceWorker.showRequestStatistics = function() {
  showRequestStatistics();
};

window.ServiceWorker.resetRequestStatistics = function() {
  resetRequestStatistics();
};
