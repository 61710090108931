export const aws_cognito_domain = "kanziwebplayer-prod.auth.eu-west-1.amazoncognito.com";

export const aws_cognito_identity_provider = "KanziAccountOIDC";

export const aws_cognito_debug_oauth_flow = false;

export const aws_amplify_app_domain = "https://player.portal.rightware.com";

export const aws_cognito_app_client_redirect_url_sign_in = process.env.NODE_ENV === "development" ? 
  "http://localhost:3000/" : `${aws_amplify_app_domain}/`;

export const aws_cognito_app_client_redirect_url_sign_out = process.env.NODE_ENV === "development" ? 
  "http://localhost:3000/signout/" : `${aws_amplify_app_domain}/signout/`;

// export const aws_cognito_app_client_redirect_url_sign_out =
//   "https://dev.account.rightware.com/logout?client_id=u3bs75vmm1rhif3bgcsgmuuh0&logout_uri=https://dev.portal.rightware.com";

// // Note: requires to be added in the Amplify Cognito => Allowed sign-out URL (full URL), 
//          logout_uri is required to be set from the https://dev.account.rightware.com IDP config
// export const aws_cognito_app_client_redirect_url_sign_out =
//   "https://dev.account.rightware.com/logout?client_id=u3bs75vmm1rhif3bgcsgmuuh0&logout_uri=http://localhost:3000/signout/";