import { Card, Divider, Header, Input, List } from "semantic-ui-react";

export const BundleInfo = ({
  bundleInfo,
  customNameValue,
  setCustomNameValue,
}) => {
  const { name, fileNames, nameSource } = bundleInfo;
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>Bundle info</Card.Header>
      </Card.Content>
      <Card.Content>
        <Header as="h4">Selected Files</Header>
        <List>
          {fileNames?.map((name) => {
            return <List.Item key={name}>{name}</List.Item>;
          })}
        </List>
        <Divider />
        <Header as="h4">Bundle name</Header>
        <p>
          The default bundle name will be{" "}
          <span style={{ color: "blue" }}>{name}</span> based on the{" "}
          <span style={{ color: "blue" }}>{nameSource}</span> file.
        </p>
        {/* <span>You can override the name</span> */}
        <Input
          label="Custom Bundle Name"
          type="text"
          value={customNameValue}
          onChange={(e) => setCustomNameValue(e.target.value)}
        />
      </Card.Content>
    </Card>
  );
};
