import HtmlMapper from "react-html-map";
import { Comment, Icon, Image } from "semantic-ui-react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export const BundleComment = ({
  comment,
  isChild,
  setThreadParentID,
  isActiveThread,
  isOwnedByUser,
  deleteHandler,
  editHandler,
}) => {
  const tagMap = {
    p: null,
    h1: null,
    h2: null,
    h3: null,
    h4: null,
    h5: null,
    strong: null,
    italic: null,
    link: null,
  };

  const renderCommentActions = () => {
    return (
      <Comment.Actions>
        {!isChild && !isActiveThread && (
          <Comment.Action onClick={() => setThreadParentID(comment.id)}>
            Reply
          </Comment.Action>
        )}
        {isOwnedByUser && (
          <>
            <Comment.Action onClick={() => editHandler(comment.id)}>
              Edit
            </Comment.Action>
            <Comment.Action onClick={() => deleteHandler(comment.id)}>
              Delete
            </Comment.Action>
          </>
        )}
      </Comment.Actions>
    );
  };

  if (comment) {
    return (
      <Comment>
        {/* <Comment.Avatar src="images/placeholder/50x50.png" /> */}
        <Comment.Content>
          <div className="commentAuthor">
            <Comment.Author as="a">{comment.author ?? "-"}</Comment.Author>
          </div>
          <Comment.Metadata>
            <div>{new Date(comment.createdAt).toLocaleString()}</div>
          </Comment.Metadata>
          <Comment.Text>
            <HtmlMapper html={comment.content}>
              {{
                ...tagMap,
                img: ({ src }) => {
                  return (
                    <Zoom>
                      <Image src={src} fluid />
                    </Zoom>
                  );
                },
              }}
            </HtmlMapper>
          </Comment.Text>
          {renderCommentActions()}
        </Comment.Content>
      </Comment>
    );
  }
  return null;
};
