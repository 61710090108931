import { useEffect } from "react";

const proxyMatchPath = '/engine';

export const KanziView = ({playerVersion, onErrorHandler}) => {
  let isLoaded = false;

  const handleScriptError = (event) => {
    console.error('[React]: Script loading error:', event.target.src);
    if (onErrorHandler) {
      onErrorHandler(`Script loading error: ${event.target.src}`);
    }
  };

  const loadWASM = async () => {
    const utilScript = document.createElement("script");
    utilScript.src = "kzb_util.js";
    utilScript.async = false;
    document.body.appendChild(utilScript);

    const script = document.createElement('script');
    script.src = `${proxyMatchPath}/${playerVersion}/kzb_player.js`;
    script.async = false;
    script.crossOrigin = "use-credentials"; // use cookies to send user jwt access token
    script.fetchpriority = "high";
    script.onerror = handleScriptError;
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!isLoaded) {
      loadWASM();
      // eslint-disable-next-line
      isLoaded = true;
      console.log('[React]: KanziView render');
    }
  }, []);

  return (
    <canvas
      id="canvas"
      style={{
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
      }}
      onContextMenu={(event) => event.preventDefault()}
      tabIndex="-1"
    />
  );
};
