import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';

import { aws_cognito_debug_oauth_flow, aws_cognito_identity_provider } from "../aws-custom-config";
import { AmplifyConfigure } from '../utils/configure';
import usePreviousEffect from '../utils/helper';
import AuthSignIn from './authSignIn';

AmplifyConfigure();

const withAuth = (WrappedComponent) => {
  return function WithAuthComponent(props) {
    const [user, setUser] = useState(null);
    const [authState, setAuthState] = useState(null);
    const [signInState, setSignInState] = useState(false);

    const location = useLocation();

    const checkUser = async () => {
      try {
        const authUser = await Auth.currentAuthenticatedUser();
        console.log('[Auth]: Check currentAuthenticatedUser()', authUser);
        setUser(authUser);
        setAuthState('authenticated');
      } catch (error) {
        console.log('[Auth]: Error:', error);
        setUser(null);
        setAuthState('unauthenticated');
      }
    };

    const getIdToken = async () => {
      try {
        if (user) {
          const session = await Auth.currentSession();
          console.log("[Auth]: Session: ", session);
          return session.getIdToken().getJwtToken();
        }
        return null;
      } catch (error) {
        console.error('[Auth]: Error getting ID token:', error);
        return null;
      }
    };

    const getAccessToken = async () => {
      try {
        if (user) {
          const session = await Auth.currentSession();
          return session.getAccessToken().getJwtToken();
        }
        return null;
      } catch (error) {
        console.error('[Auth]: Error getting access token:', error);
        return null;
      }
    };
  
    const signIn = useCallback(async () => {
      if(!aws_cognito_debug_oauth_flow && location.pathname.includes('signout')) {
        console.log("[Auth]: Skip auto signIn, detected 'signout' path");
        return;
      }

      console.log("[Auth]: call signIn()");
      try {
        await Auth.federatedSignIn({ customProvider: aws_cognito_identity_provider });
        checkUser();
      } catch (error) {
        console.error('[Auth]: federatedSignIn error:', error);
        setAuthState('signIn_failure');
      }
    }, [location.pathname]);

    const signOut = async () => {
      try {
        console.log("[Auth]: call signOut()");
        setUser(null);
        setAuthState('signOut');
        await Auth.signOut();
      } catch (error) {
        console.error('[Auth]: signOut error:', error);
        setAuthState('signOut_failure');
      }
    };

    useEffect(() => {
      const unsubscribe = Hub.listen('auth', (data) => {
        const { payload } = data
        const { event } = payload;
        console.log('[Auth]: A new auth event has happened: ', event, data);
        if (event) {
          setAuthState(event);
        }
        switch (event) {
          case "signIn":
          case "cognitoHostedUI":
            console.log('[Auth]: A user has signed in!')
            break;
          case "signOut":
            console.log('[Auth]: A user has signed out!')
            break;
          case "signIn_failure":
          case "cognitoHostedUI_failure":
            console.log("[Auth]: Sign in failure", data);
            break;
          case "customOAuthState":
            console.log("[Auth]: OAuth custom state", data);
            break;
          default:
            break;
        }
      });
      return unsubscribe;
    }, [])

    useEffect(() => {
      console.log('[Auth]: Check User');
      checkUser();
    }, []);

    useEffect(() => {
      if (aws_cognito_debug_oauth_flow) {
        return;
      }
      if(authState === 'unauthenticated') {
        setUser(null);
        setSignInState(true);
      }
    }, [authState, setUser, setSignInState]);

    usePreviousEffect(
      ([prevSignInState]) => {
        if (prevSignInState === false && signInState === true) {
          console.log("[Auth]: Auto trigger sign in, authState:", authState);
          signIn();
        }
      },
      [signInState]
    );

    return (
      <>
        {user ? (
            <WrappedComponent
              user={user}
              signIn={signIn}
              signOut={signOut}
              getIdToken={getIdToken}
              getAccessToken={getAccessToken}
              {...props}
            />
          ) : (
            <AuthSignIn state={authState} signIn={signIn} />
          )
        }
        {!aws_cognito_debug_oauth_flow && !user && location.pathname.includes('signout') ? (
              <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
              }}
            >
              <span>You have been successfully signed out.</span>
            </div>
            ) : null
          }
      </>
    );
  };
};

export default withAuth;